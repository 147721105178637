import ApiService from "@/common/api.service";

// action type
export const GET_LIST_TEACHER = "/teacher";
export const DETAIL_TEACHER = "/detail";
export const SAVE_TEACHER = "/create-teacher";
export const UPDATE_TEACHER = "/update/teacher";
export const DELETE_TEACHER = "/teacher/delete";
export const SET_LIST_TEACHER = "setListTeacher";
export const SET_DETAIL_TEACHER = "setDetailTeeacher";

export const SET_ERROR = "setError";

const state = {
    errors: null,
    teachers: [],
    teacher: {},
};

const getters = {
    listTeachers(state) {
        return state.teachers;
    },
    detailTeacher(state) {
        return state.teacher;
    }
};

const actions = {
    [GET_LIST_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST_TEACHER, payload)
                .then(( data ) => {
                    context.commit(SET_LIST_TEACHER, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                });
        });
    },
    [DETAIL_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(DETAIL_TEACHER, payload)
                .then(( data ) => {
                    context.commit(SET_DETAIL_TEACHER, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                });
        });
    },

    [SAVE_TEACHER](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_TEACHER, payload)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },

    [UPDATE_TEACHER](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_TEACHER}/${payload.id}`, payload)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },

    [DELETE_TEACHER](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_TEACHER, payload)
                .then(( data ) => {
                    resolve(data);
                  })
                  .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                  });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LIST_TEACHER](state, data) {
        state.teachers = data;
        state.errors = {};
    } ,
    [SET_DETAIL_TEACHER](state, data) {
        state.teacher = data;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
