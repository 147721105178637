import ApiService from "@/common/api.service";

// action types
export const SAVE_NEWS_CATEGORY = "/news-category/save";
export const DETAIL_CATEGORY = "/news-category/get-detail";
export const UPDATE_NEWS_CATEGORY = "/news-category/update";

export const GET_LIST_NEWS_CATEGORY = "/news-category/list";
export const SEARCH_NEWS_CATEGORY_BY_NAME = "/news-category/search-by-name";
export const DELETE_NEWS_CATEGORY = "/news-category/delete";
export const COUNT_CATEGORY = "/news-category/count";


// mutation types
export const SET_CATEGORY = "setCategory";
export const SET_DETAIL_CATEGORY = "setDetailCategory";
export const SET_COUNT_CATEGORY = "setCountCategory";
export const SET_NAME_CATEGORY = "setNameCategory";
export const SET_IMAGE = "setImage";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  newscategories: [],
  categoryCount: 0,
  category: {},
  nameOfNewscategories: [],
  images: [],
};

const getters = {
  listNewsCategories(state) {
    return state.newscategories;
  },
  listNameOfNewsCategories(state) {
    return state.nameOfNewscategories;
  },
  listImages(state) {
    return state.images;
  },
  categoryCount(state) {
    return state.categoryCount;
  },
  category(state) {
    return state.category;
  },
};

const actions = {
  [GET_LIST_NEWS_CATEGORY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(GET_LIST_NEWS_CATEGORY, payload)
        .then(({ data }) => {
          context.commit(SET_CATEGORY, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response);
        });
    });
  },
  [SEARCH_NEWS_CATEGORY_BY_NAME](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(SEARCH_NEWS_CATEGORY_BY_NAME, payload)
        .then(({ data }) => {
          context.commit(SET_NAME_CATEGORY, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [COUNT_CATEGORY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(COUNT_CATEGORY, payload).then(data => {
        if (data.status) {
          context.commit(SET_COUNT_CATEGORY, data);
        }
        resolve(data);
      }).catch(({ response }) => {
        reject(response);
      });
    });
  },
  [DETAIL_CATEGORY](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(DETAIL_CATEGORY, payload)
        .then(data => {
          if (data.status) {
            context.commit(SET_DETAIL_CATEGORY, data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [SAVE_NEWS_CATEGORY](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(SAVE_NEWS_CATEGORY, payload)
        .then( data => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [UPDATE_NEWS_CATEGORY](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPDATE_NEWS_CATEGORY, payload)
        .then( data => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DELETE_NEWS_CATEGORY](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.post(DELETE_NEWS_CATEGORY, id)
        .then(({ data }) => {
          if (data.status) {
            context.commit(SET_CATEGORY, data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_CATEGORY](state, data) {
    state.newscategories = data;
    state.errors = {};
  }, 
  [SET_DETAIL_CATEGORY](state, data) {
    state.category = data.data;
    state.errors = {};
  },
  [SET_NAME_CATEGORY](state, data) {
    state.nameOfNewscategories = data;
    state.errors = {};
  },
  [SET_COUNT_CATEGORY](state, data) {
    state.categoryCount = data.data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
