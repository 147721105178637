import ApiService from "@/common/api.service";
import API from "@/API";

// action types
export const GET_REPORT_LIST = "/reports";
export const APPROVE_REPORT = "APPROVE_REPORT";
export const DENY_REPORT = "DENY_REPORT";

// mutation types
export const SET_REPORT_LIST = "setReportList";

const state = {
    reports: [],
};

const getters = {
    listReport(state) {
        return state.reports;
    },
};

const actions = {
    [GET_REPORT_LIST](context,payload) {
        return new Promise((resolve) => {
            ApiService.query(GET_REPORT_LIST,payload)
                .then(( data ) => {
                    context.commit(SET_REPORT_LIST, data.data);
                    resolve(data);
                })
        });
    },
    [APPROVE_REPORT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(API.APPROVE_REPORT, payload.id,payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [DENY_REPORT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(API.DENY_REPORT, payload.id,payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_REPORT_LIST](state, data) {
        state.reports = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
