import ApiService from "@/common/api.service";

// action type
export const GET_LIST_MENU_FOOTER = "/menu-footers";
export const GET_DETAIL_MENU_FOOTER = "/menu-footers-detail";
export const SAVE_MENU_FOOTER = "/menu-footer/create";
export const UPDATE_MENU_FOOTER = "/menu-footer/update";
export const DELETE_MENU_FOOTER = "/menu-footer/delete";
export const SET_LIST_MENU_FOOTER = "setListMenuFooter";
export const SET_DETAIL_MENU_FOOTER = "setDetailMenuFooter";

export const SET_ERROR = "setError";

const state = {
    errors: null,
    menu_footer: [],
    menufooter: {},
};

const getters = {
    listMenuFooter(state) {
        return state.menu_footer;
    },
    detailMenuFooter(state) {
        return state.menufooter;
    }
};

const actions = {
    [GET_LIST_MENU_FOOTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST_MENU_FOOTER, payload)
                .then(( data ) => {
                    context.commit(SET_LIST_MENU_FOOTER, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                });
        });
    },

    [GET_DETAIL_MENU_FOOTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_DETAIL_MENU_FOOTER, payload)
                .then(( data ) => {
                    context.commit(SET_DETAIL_MENU_FOOTER, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                });
        });
    },

    [SAVE_MENU_FOOTER](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_MENU_FOOTER, payload)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },

    [UPDATE_MENU_FOOTER](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_MENU_FOOTER}/${payload.id}`, payload)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },

    [DELETE_MENU_FOOTER](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_MENU_FOOTER, payload)
                .then(( data ) => {
                    resolve(data);
                  })
                  .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                  });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LIST_MENU_FOOTER](state, data) {
        state.menu_footer = data;
        state.errors = {};
    } ,
    [SET_DETAIL_MENU_FOOTER](state, data) {
        state.menufooter = data;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
