import ApiService from "@/common/api.service";
import {UPDATE_SUBJECT} from "./subject.module";

// action types
export const GET_DETAIL_SETTING = "/setting/get-setting-by-key";
export const UPDATE_SETTING = "/setting/update-setting-by-key";

// mutation types
export const SET_SETTING = "setSetting";

const state = {
  setting: '',
};

const getters = {
  setting(state) {
    return state.setting;
  }
};

const actions = {
  [GET_DETAIL_SETTING](context,payload) {
    return new Promise(resolve => {
      ApiService.query(GET_DETAIL_SETTING,payload)
        .then(( data ) => {
          if (data.status) {
            context.commit(SET_SETTING, data.data);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [UPDATE_SETTING](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPDATE_SETTING, payload)
          .then(( data ) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
};

const mutations = {
  [SET_SETTING](state, value) {
    state.setting = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
