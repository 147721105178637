import ApiService from "@/common/api.service";

// action types
export const GET_TOPIC_ALL = "/topic";
export const GET_DIRECT_CHILDREN = "/topic/direct-children";
export const GET_BY_GRADE = "/topic/find-by-grade";
export const GET_TOPIC_DETAIL = "/topic/detail";
export const UPDATE_TOPIC = "/topic/update";
export const UPDATE_CHILDREN_TOPIC = "/topic/children-topic/update";
export const UPDATE_CHILDREN_LESSON = "/topic/children-lesson/update";
export const SAVE_TOPIC = "/topic/save";
export const DELETE_TOPIC = "/topic/delete";

export const SEARCH_TOPIC_BY_NAME = "/topic/search-by-name";

// mutation types
export const SET_TOPIC_ALL = "setTopicAll";
export const SET_DIRECT_CHILDREN = "setDirectChildren";
export const SET_TOPIC_BY_GRADE = "setTopicByGrade";
export const SET_TOPIC_DETAIL = "setTopicDetail";
export const SET_ERROR = "setError";
export const SET_NAME_CATEGORY = "setNameCategory";

const state = {
  topics_by_grade: [],
  topic: {},
  topics:[],
  topics_children:[],
  topics_by_name: []
};

const getters = {
  topicAll(state) {
    return state.topics;
  },
  topicListByGrade(state) {
    return state.topics_by_grade;
  },
  topicDetail(state) {
    return state.topic;
  },
  topicListByName(state) {
    return state.topics_by_name;
  },
  topicListChildren(state) {
    return state.topics_children;
  },
};

const actions = {
  [GET_TOPIC_ALL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(GET_TOPIC_ALL, payload)
        .then(({ data }) => {
          context.commit(SET_TOPIC_ALL, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response)
        });
    });
  },
  [GET_DIRECT_CHILDREN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(GET_DIRECT_CHILDREN, payload)
        .then(({ data }) => {
          context.commit(SET_DIRECT_CHILDREN, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response)
        });
    });
  },
  [GET_BY_GRADE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(GET_BY_GRADE, payload)
        .then(({ data }) => {
          context.commit(SET_TOPIC_BY_GRADE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response)
        });
    });
  },
  [GET_TOPIC_DETAIL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(GET_TOPIC_DETAIL, payload)
        .then(({ data }) => {
          context.commit(SET_TOPIC_DETAIL, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response)
        });
    });
  },
  [SEARCH_TOPIC_BY_NAME](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(SEARCH_TOPIC_BY_NAME, payload)
          .then(({ data }) => {
            context.commit(SET_NAME_CATEGORY, data);
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  [UPDATE_TOPIC](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPDATE_TOPIC, payload)
        .then(data => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },[UPDATE_CHILDREN_TOPIC](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPDATE_CHILDREN_TOPIC, payload)
        .then(data => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },[UPDATE_CHILDREN_LESSON](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPDATE_CHILDREN_LESSON, payload)
        .then(data => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [SAVE_TOPIC](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(SAVE_TOPIC, payload)
        .then(data => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DELETE_TOPIC](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(DELETE_TOPIC, payload)
        .then(data => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_TOPIC_ALL](state, data) {
    state.topics = data;
    state.errors = {};
  },
  [SET_TOPIC_BY_GRADE](state, data) {
    state.topics_by_grade = data;
    state.errors = {};
  },
  [SET_TOPIC_DETAIL](state, data) {
    state.topic = data;
    state.errors = {};
  },
  [SET_NAME_CATEGORY](state, data) {
    state.topics_by_name = data;
    state.errors = {};
  },
  [SET_DIRECT_CHILDREN](state, data) {
    state.topics_children = data;
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
