import ApiService from "@/common/api.service";

// action types
export const SAVE_SUBJECT = "/subject/save";
export const UPDATE_SUBJECT = "/subject/update";

export const GET_LIST_SUBJECTS = "/subject/list";
export const GET_DETAIL_SUBJECT = "/subject/detail";
export const DELETE_SUBJECT = "/subject/delete";

export const SET_DEFAULT_SUBJECT = "setDefaultSubject";

// mutation types
export const SET_SUBJECT = "setSubject";
export const SET_DETAIL_SUBJECT = "setDetailSubject";
export const SET_IMAGE = "setImage";
export const SET_ERROR = "setError";
export const SET_FILTER_SUBJECT = "SET_FILTER_SUBJECT";

const state = {
  errors: null,
  subjects: [],
  subject: {},
  images: [],
};

const getters = {
  listSubjects(state) {
    return state.subjects;
  },
  listImages(state) {
    return state.images;
  }
};

const actions = {
  [GET_LIST_SUBJECTS](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(GET_LIST_SUBJECTS,payload)
        .then(( data ) => {
          context.commit(SET_SUBJECT, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response);
        });
    });
  }, 
  [GET_DETAIL_SUBJECT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.query(GET_DETAIL_SUBJECT, payload)
        .then(( data ) => {
          context.commit(SET_DETAIL_SUBJECT, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response);
        });
    });
  },
  [SAVE_SUBJECT](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(SAVE_SUBJECT, payload)
        .then(( data ) => {
          if (data.status) {
            context.commit(UPDATE_SUBJECT, data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [UPDATE_SUBJECT](context,payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPDATE_SUBJECT, payload)
        .then(( data ) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DELETE_SUBJECT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(DELETE_SUBJECT, payload)
        .then(( data ) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response);
        });
    });
  },
  [SET_DEFAULT_SUBJECT](context) {
    context.commit(SET_DETAIL_SUBJECT, {});
},
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_SUBJECT](state, data) {
    state.subjects = data;
    state.subjects.forEach( subject => {
      let img = JSON.parse(subject.img);
      if (img && img.path && img.name)
        subject.img = process.env.VUE_APP_API_URL + "/" + img.path + img.name;
    });
    state.errors = {};
  },
  [SET_DETAIL_SUBJECT](state, data) {
    data.gradeId = []
    if (data && data.grades){
      data.gradeId = data.grades.map(item => item.id)
    }
    state.subject = data;

    state.errors = {};
  },
  [UPDATE_SUBJECT](state, data) {
    state.subjects.push(data.data);
    state.errors = {};
  },
  [SET_FILTER_SUBJECT](state, id) {
    state.subjects = state.subjects.filter(item =>{
      return item.id !== id
    })
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
