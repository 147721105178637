import {DETAIL_ASK, LIST_ASKS, SAVE_ASK, UPDATE_ASK} from "@/store/ask.module";
import {DETAIL_ANSWER, LIST_ANSWERS, UPDATE_ANSWER} from "./store/answer.module";

const API = {
    GET_LIST_USER_EX:'/users',
    DELETE_USER_EX:'/users',
    APPROVE_REPORT:'/reports',
    DENY_REPORT:'/reports',
    LIST_ASKS:'/asks',
    SAVE_ASK:'/asks',
    UPDATE_ASK:'/asks',
    DETAIL_ASK:'/asks',
    LIST_ANSWERS:'/answers',
    DETAIL_ANSWER:'/answers',
    UPDATE_ANSWER:'/answers',
    DELETE_ANSWER:'/answers',

}
export default  API