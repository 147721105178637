import ApiService from "@/common/api.service";

// action types
export const SAVE_IMAGE = "/upload/image";
export const DELETE_IMAGE = "/delete/image";


// mutation types

const state = {
      images: [],
};

const getters = {
     
};

const actions = {
      [SAVE_IMAGE](context, payload) {
            return new Promise((resolve, reject) => {
                  ApiService.post(SAVE_IMAGE, payload)
                        .then( data => {
                              resolve(data);
                        })
                        .catch(({ response }) => {
                              reject(response);
                        });
            });
      },
      [DELETE_IMAGE](context, payload) {
            return new Promise((resolve, reject) => {
                  ApiService.post(DELETE_IMAGE, payload)
                      .then(({ data }) => {
                            resolve(data);
                      })
                      .catch(({ response }) => {
                            reject(response);
                      });
            });
      }
};

const mutations = {
};

export default {
      state,
      actions,
      mutations,
      getters
};
