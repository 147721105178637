import ApiService from "@/common/api.service";
import {SET_ERROR} from "@/store/lesson.module";
import API from "@/API";

// action types
export const LIST_ASKS = "LIST_ASKS";
export const DETAIL_ASK = "DETAIL_ASK";
export const SAVE_ASK = "SAVE_ASK";
export const UPDATE_ASK = "UPDATE_ASK";
export const DELETE_ASK = "/ask/delete";


const state = {
    ask: {
        id: '',
        slug: "",
    },
    asks: [],
    total: 0,
};

const getters = {
    listAsk(state) {
        return state.asks;
    },
    detailAsk(state) {
        return state.ask;
    },
    totalAsk(state) {
        return state.total;
    },
};

const actions = {
    [LIST_ASKS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.LIST_ASKS, payload)
                .then(data => {
                    if (data.status) {
                        context.commit("setAsk", data);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

    [DETAIL_ASK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.DETAIL_ASK, payload)
                .then(data => {
                    if (data.status) {
                        context.commit("setAskDetail", data);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [SAVE_ASK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.SAVE_ASK, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [UPDATE_ASK](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${API.UPDATE_ASK}/${payload.id}`, payload.data)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [DELETE_ASK](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_ASK, id)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    setAsk(state, data) {
        state.asks = data.data;
        state.total = data.meta?.total
    },
    setAskDetail(state, data) {
        state.ask = data.data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
