import ApiService from "@/common/api.service";

// action types

export const GET_LIST_NOTIFICATION = "/notifications";
export const COUNT_NOTIFICATION = "/notifications";

// mutation types
export const SET_NOTIFICATION = "setNotification";
export const SET_COUNT = "setCount";

const state = {
    notifications: [],
    total:null,
};

const getters = {
    listNotification(state) {
        return state.notifications;
    },
    totalNotification(state) {
        return state.total;
    },
};

const actions = {
    [GET_LIST_NOTIFICATION](context,payload) {
        return new Promise((resolve) => {
            ApiService.query(GET_LIST_NOTIFICATION,payload)
                .then(( data ) => {
                    context.commit(SET_NOTIFICATION, data.data);
                    resolve(data);
                })
        });
    },
    [COUNT_NOTIFICATION](context,payload) {
        return new Promise((resolve) => {
            ApiService.query(COUNT_NOTIFICATION,payload)
                .then(( data ) => {
                    context.commit(SET_COUNT, data.data);
                    resolve(data);
                })
        });
    },
};

const mutations = {
    [SET_NOTIFICATION](state, data) {
        state.notifications = data;
    },
    [SET_COUNT](state, data) {
        state.total = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
