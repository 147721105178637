import ApiService from "@/common/api.service";
import API from "../API";

// action types
export const LIST_ANSWERS = "LIST_ANSWERS";
export const DETAIL_ANSWER = "DETAIL_ANSWER";
export const SAVE_ANSWER = "/answer/save";
export const UPDATE_ANSWER = "UPDATE_ANSWER";
export const DELETE_ANSWER = "DELETE_ANSWER";


const state = {
    answer: {},
    answers: [],
    total: 0,
};

const getters = {
    listAnswer(state) {
        return state.answers;
    },
    detailAnswer(state) {
        return state.answer;
    },
    totalAnswer(state) {
        return state.total;
    },
};

const actions = {
    [LIST_ANSWERS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.LIST_ANSWERS, payload)
                .then(data => {
                    if (data.status) {
                        context.commit("setAnswer", data);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

    [DETAIL_ANSWER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.DETAIL_ANSWER}/${payload.id}`)
                .then(data => {
                    if (data.status) {
                        context.commit("setAnswerDetail", data);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [SAVE_ANSWER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_ANSWER, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [UPDATE_ANSWER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${API.UPDATE_ANSWER}/${payload.id}`, payload.data)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [DELETE_ANSWER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${API.DELETE_ANSWER}`,id)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    setAnswer(state, data) {
        state.answers = data.data;
        state.total = data.meta.total;
    },
    setAnswerDetail(state, data) {
        state.answer = data.data;
    },


};

export default {
    state,
    actions,
    mutations,
    getters
};
