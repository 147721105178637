import ApiService from "@/common/api.service";
import API from "@/common/API";

// action types
export const DETAIL_LESSON = "DETAIL_LESSON";
export const SAVE_LESSON = "SAVE_LESSON";
export const ADD_CATEGORY_LESSON = "/lesson/add-news-category";
export const DELETE_CATEGORY_LESSON = "/lesson/delete-news-category";
export const UPDATE_LESSON = "UPDATE_LESSON";
export const GET_LIST_LESSONS = "/lessons";
export const DELETE_LESSON = "DELETE_LESSON";


// mutation types
export const SET_LESSON = "setLesson";
export const SET_DETAIL_LESSON = "setDetailLesson";
export const SET_COUNT_LESSON = "setCountLesson";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    lessons: [],
    detailLesson: {},
    lesson_count: 0,
    images: [],
};

const getters = {
    listLessons(state) {
        return state.lessons;
    },
    listImages(state) {
        return state.images;
    },
    lessonCount(state) {
        return state.lesson_count;
    },
    detailLesson(state) {
        return state.detailLesson;
    },
};

const actions = {
    [GET_LIST_LESSONS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST_LESSONS, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_LESSON, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },

    [ADD_CATEGORY_LESSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(ADD_CATEGORY_LESSON, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_DETAIL_LESSON, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [DELETE_CATEGORY_LESSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(DELETE_CATEGORY_LESSON, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_DETAIL_LESSON, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [DETAIL_LESSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.DETAIL_LESSON, payload.slug)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_DETAIL_LESSON, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [SAVE_LESSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.SAVE_LESSON, payload)
                .then(data => {
                    // if (data.status) {
                    //   context.commit(SET_LESSON, data);
                    // } else {
                    //   context.commit(SET_ERROR, data.message);
                    // }
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
    [UPDATE_LESSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.UPDATE_LESSON}/${payload.id}`, payload.content)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [DELETE_LESSON](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.DELETE_LESSON, id)
                .then(data => {
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LESSON](state, data) {
        state.lessons = data.data;
        state.lesson_count = data.total;
        state.errors = {};
    },
    [SET_DETAIL_LESSON](state, data) {
        state.detailLesson = data.data;
        state.errors = {};
    },
    [UPDATE_LESSON](state, data) {
        state.lessons.push(data.data);
        state.errors = {};
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
