import ApiService from "@/common/api.service";
import API from "@/API";
// action types
export const GET_LIST_USER_EX = "GET_LIST_USER_EX";
export const DETAIL_TUTOR = "/user/detail-tutor";
export const UPDATE_TUTOR = "/user/update-tutor";
export const DELETE_USER_EX = "DELETE_USER_EX";

// mutation types
export const SET_USER_EX = "SET_USER_EX";
export const SET_TUTOR = "setUser";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    usersEx: [],
    user:{},
    totalUser: 0
};

const getters = {
    usersEx(state) {
        return state.usersEx;
    },
    tutor(state) {
        return state.user;
    },
    totalUser(state) {
        return state.totalUser;
    },
};

const actions = {
    [GET_LIST_USER_EX](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.GET_LIST_USER_EX, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_USER_EX, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [DETAIL_TUTOR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(DETAIL_TUTOR, payload)
                .then(data => {
                    if (data.status) {
                        context.commit(SET_TUTOR, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
        [DELETE_USER_EX](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.delete(API.DELETE_USER_EX, payload)
                    .then(data => {
                        resolve(data);
                    })
                    .catch(({response}) => {
                        reject(response);
                    });
            });
        },
    [UPDATE_TUTOR](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.patch(UPDATE_TUTOR, payload)
                    .then(data => {
                        resolve(data);
                    })
                    .catch(({response}) => {
                        reject(response);
                    });
            });
        },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_USER_EX](state, data) {
        state.usersEx = data.data;
        state.errors = {};
        state.totalUser = data.total
    },
    [SET_TUTOR](state, data) {
        state.user = data.data;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
