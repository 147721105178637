import ApiService from "@/common/api.service";

// action type
export const GET_LIST_MEDIA_PRESS = "/list-media-press";
export const GET_DETAIL_MEDIA_PRESS = "/media-press/detail";
export const SAVE_MEDIA_PRESS = "/media-press/create";
export const UPDATE_MEDIA_PRESS = "/media-press/update";
export const DELETE_MEDIA_PRESS = "/media-press/delete";
export const SET_LIST_MEDIA_PRESS = "setListMediaPress";
export const SET_LIST_PRIZES = "setListPrize";
export const SET_DETAIL_MEDIA_PRESS = "setDetailMediaPress";

export const SET_ERROR = "setError";

const state = {
    errors: null,
    media_press: [],
    mediapress: {},
};

const getters = {
    listMediaPress(state) {
        return state.media_press;
    },
    detailMediaPress(state) {
        return state.mediapress;
    }
};

const actions = {
    [GET_LIST_MEDIA_PRESS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST_MEDIA_PRESS, payload)
                .then(( data ) => {
                    context.commit(SET_LIST_MEDIA_PRESS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                });
        });
    },

    [GET_DETAIL_MEDIA_PRESS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_DETAIL_MEDIA_PRESS, payload)
                .then(( data ) => {
                    context.commit(SET_DETAIL_MEDIA_PRESS, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                });
        });
    },

    [SAVE_MEDIA_PRESS](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_MEDIA_PRESS, payload)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },

    [UPDATE_MEDIA_PRESS](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_MEDIA_PRESS}/${payload.id}`, payload)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },

    [DELETE_MEDIA_PRESS](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_MEDIA_PRESS, payload)
                .then(( data ) => {
                    resolve(data);
                  })
                  .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                  });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LIST_MEDIA_PRESS](state, data) {
        state.media_press = data.data;
        state.errors = {};
    } ,
    [SET_DETAIL_MEDIA_PRESS](state, data) {
        state.mediapress = data;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
